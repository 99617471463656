import React, { useEffect } from 'react';
import { FaRunning, FaBed } from 'react-icons/fa';
import styles from '../stylez/AdminPage.module.css';
import { useAdmin } from '../react-query/useAdmin';
import { useState } from 'react';
import FailedPopup from '../components/AdminComponents/VerifiyFailedPopup';
import { useEvents } from '../react-query/useEvents';
import { addDays, differenceInDays, format } from "date-fns";
import TotalHoursCounter from "../components/HoursComponents/TotalHoursCounter";
import HoursWorkedCardsList from "../components/HoursComponents/HoursWorkedCardList";
import BiWeeklyToolBar from "../components/HoursComponents/BiWeeklyToolBar";
import AllHoursCardList from '../components/AdminComponents/AllHours';

  const AdminPage = () => {
    const { users, verify, adminVerificationError } = useAdmin();
    const [showPopup, setShowPopup] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const epochStartDate = new Date(2022, 9, 17); // October 17, 2022

  // This will format the week for the biweekly period
  const formatWeek = (date) => {
    if (!date || !(date instanceof Date)) return '';
    const daysSinceEpoch = differenceInDays(date, epochStartDate);
    const currentPeriodStart = addDays(epochStartDate, Math.floor(daysSinceEpoch / 14) * 14);
    const currentPeriodEnd = addDays(currentPeriodStart, 13);
    return { startDate: format(currentPeriodStart, 'MM/dd/yyyy'), endDate: format(currentPeriodEnd, 'MM/dd/yyyy') };
  };

  // Set the start and end date based on the current date
    useEffect(() => {
        const { startDate, endDate } = formatWeek(currentDate);
    setStartDate(startDate);
    setEndDate(endDate);
  }, [currentDate]);

  // Fetch events using the 'all' fetchType
  const { events, isLoading, isError, error } = useEvents({
    startDate: startDate,
    endDate: endDate,
    fetchType: 'all' // Fetch events for all coaches
  });
  console.log(events);

  // Verify admin on mount
  useEffect(() => {
        const verifyAdmin = async () => {
          await verify();
        };
        verifyAdmin();
      }, []);
      
// Show popup if there's an admin verification error
      useEffect(() => {
        console.log('adminVerificationError effect');
        console.log(showPopup);
        console.log(adminVerificationError);
      
        if (adminVerificationError) {
          console.log('adminVerificationError detected');
          setShowPopup(true);
        }
      }, [adminVerificationError]);

    return (
      <div className={styles.adminPageContainer}>
        <h1 style={{textAlign:"center"}}>All Scheduled Hours</h1>
      <BiWeeklyToolBar currentDate={currentDate} setCurrentDate={setCurrentDate} startDate={startDate} endDate={endDate} />
      {isLoading && <p>Loading...</p>}
      {isError && <p>Error: {error.message}</p>}
      {!isLoading && !isError && (
        <>
          <TotalHoursCounter events={events} type='all'/>
          <AllHoursCardList events={events} />
        </>
      )}
        {showPopup && adminVerificationError && (
        <FailedPopup message={adminVerificationError} onClose={() => setShowPopup(false)} />
      )}
    </div>
    );
  };
  
  export default AdminPage;