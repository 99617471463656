// useFeatureReq.js
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { sendFeatureRequest, getFeatureRequests, deleteFeatureRequest } from '../services/api_data';
import { toast } from 'react-toastify';


const useFeatureReq = () => {

    const queryClient = useQueryClient();

    const mutation = useMutation(sendFeatureRequest, {
        onError: (error) => {
            console.log(error);
            // toast.error(`Oops! Looks like there was a problem.`);
        },
        onSuccess: () => {
            toast.success('Feature request submitted successfully.');
        }
    });

    // Mutation for deleting a feature request
    const deleteMutation = useMutation(deleteFeatureRequest, {
        onMutate: async (featureRequestId) => {
            // Get the current feature requests from the cache
            const prevRequests = queryClient.getQueryData('featureRequests');

            // Optimistically remove the deleted request from the data
            if (prevRequests) {
                queryClient.setQueryData('featureRequests', prevRequests.filter(request => request._id !== featureRequestId));
            }

            return { prevRequests };
        },
        onError: (error, variables, context) => {
            // Revert the optimistic update if the deletion fails
            if (context && context.prevRequests) {
                queryClient.setQueryData('featureRequests', context.prevRequests);
            }
            toast.error('Failed to delete feature request.');
        },
        onSuccess: () => {
            toast.success('Feature request deleted successfully.');
        },
    });

    const fetchFeatureRequests = async () => {
        try {
            const data = await getFeatureRequests();
            return data;
        } catch (error) {
            console.error('Failed to fetch feature requests:', error);
            throw error;
        }
    };

    // Assuming "featureRequests" as a query key. This key should be unique across your application
    // for this specific data fetch. Adjust the "staleTime" and "cacheTime" as per your requirements.
    const { data: requests, error, isLoading } = useQuery('featureRequests', fetchFeatureRequests, {
        // staleTime: 5 * 60 * 1000, // Optional: data is considered fresh for 5 minutes
        cacheTime: 30 * 60 * 1000, // Optional: data is cached for 30 minutes
        onError: (err) => {
            toast.error('Failed to fetch feature requests.');
        },
        refetchOnMount: true,
        refetchOnWindowFocus: true, // This is true by default


    });
    
    const createFeatureRequest = async (featureTitle, featureBody, setError) => {
        try {
const timestamp = new Date().toISOString(); // Generate the timestamp
        const response = await mutation.mutateAsync({
            title: featureTitle,
            body: featureBody,
timestamp, // Add the timestamp to the mutation
        });
        return response;
        } catch (error) {
        setError(true);
            console.log('request error:', error);
            toast.error(error.response.data.detail || `Oops! Looks like there was a problem.`);
        console.error('Failed to create feature request:', error);
        // throw error;
        }
    };

    // Function to delete a feature request
    const deleteFeatureReq = async (featureRequestId) => {
        try {
            await deleteMutation.mutateAsync(featureRequestId);
        } catch (error) {
            console.log('request delete error:', error);
            // toast.error(error.response.data.detail || `Oops! Looks like there was a problem deleting.`);
            console.error('Failed to delete feature request:', error);
        }
    };


    return { createFeatureRequest, deleteFeatureReq, requests, isLoading, error, ...mutation };
};

export default useFeatureReq;
