// useAdmin.js
import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom'; // Import useLocation
import { getUsers, makeUserAdmin } from '../services/api_data';
import { verifyAdmin } from '../services/api_auth';
import { toast } from 'react-toastify';



export const useAdmin = () => {
  const queryKey = ['Users'];
  const queryClient = useQueryClient();
  const [isAdmin, setIsAdmin] = useState(false); // State to track if user is admin
  const [adminVerificationError, setAdminVerificationError] = useState(null);
  const location = useLocation(); // Use the useLocation hook to get the current location

  const verify = async () => {
    try {
      const result = await verifyAdmin();
      if (result === 'invalid') {
        setIsAdmin(false);
        setAdminVerificationError(true);
        console.log('invalid');
      } else {
        setIsAdmin(true);
        setAdminVerificationError(false);
        console.log('valid');
      }
    } catch (error) {
      console.log('Verification error:', error);
      setIsAdmin(false);
      setAdminVerificationError(true);
    }
  };


  // Define the mutation
  const { mutate: updateUserAdminStatus, isLoading: isUpdating } = useMutation(
    ({ userId, isAdmin }) => makeUserAdmin(userId, isAdmin),
    {
      // Before the mutation is made, store the current data for potential rollback
      onMutate: async ({ userId }) => {
        await queryClient.cancelQueries(queryKey);
        const previousUsers = queryClient.getQueryData(queryKey);
        queryClient.setQueryData(queryKey, oldQueryData => {
          return oldQueryData.map(user => {
            if (user.id === userId) {
              return { ...user, is_admin: !user.is_admin }; // optimistically toggle admin status
            }
            return user;
          });
        });
        return { previousUsers };
      },
      onSuccess: () => {
        toast.success('Successfully updated admin status.');
        queryClient.invalidateQueries(queryKey); // Refetch the users to get the updated list from the server
      },
      onError: (error, variables, context) => {
        console.error('Error updating admin status:', error);
        toast.error(error.response.data.detail || 'An error occurred while updating admin status.');
        // If the mutation fails, rollback to the previous state
        queryClient.setQueryData(queryKey, context.previousUsers);
      }
    }
  );


  const { data, isLoading, isError, error, refetch } = useQuery(
    queryKey,
    getUsers,
    {
      enabled: location.pathname === '/admin/users' && !adminVerificationError,
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );

  

  return {
    users: data || [],
    isFetchingAdmin: isLoading || isUpdating,
    updateUserAdminStatus, // Expose the mutation function
    verify, // Expose the verifyAdmin function
    adminVerificationError,
    isError,
    error,
    refetch, // You can expose refetch if you want to allow manual refetching
    // webSocket, // Optionally expose WebSocket if you want to allow sending messages from components
  };
};