import { format } from 'date-fns';
import '../../stylez/HoursWorkedList.css'
import FeatureRequestCard from './FeatureReqeustsCard';

const AllRequestsCardList = ({selectRequest, requests}) => {
    console.log(requests);
    return ( 
        <div style={{maxWidth:"100%"}}>
        {requests.map((request) => (
            <FeatureRequestCard
            key={request._id}
            featureTitle={request.title}
            featureBody={request.body}
            coachName={`${request.coach.first_name} ${request.coach.last_name}`}
            isActive={request.coach.isActive}
            imageUrl={request.coach.image_url}
            timestamp={request.timestamp}
            onClick={() => selectRequest(request)}
            />
        ))}
    </div>
  );
};
 
export default AllRequestsCardList;
