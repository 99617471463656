import React, { useEffect } from 'react';
import { FaRunning, FaBed } from 'react-icons/fa';
// import styles from '../stylez/AdminPage.module.css';
import styles from '../stylez/UserRequestPage.module.css';
import { useAdmin } from '../react-query/useAdmin';
import { useState } from 'react';
import FailedPopup from '../components/AdminComponents/VerifiyFailedPopup';
import { useEvents } from '../react-query/useEvents';
import { addDays, differenceInDays, format } from "date-fns";
import TotalHoursCounter from "../components/HoursComponents/TotalHoursCounter";
import HoursWorkedCardsList from "../components/HoursComponents/HoursWorkedCardList";
import BiWeeklyToolBar from "../components/HoursComponents/BiWeeklyToolBar";
import AllHoursCardList from '../components/AdminComponents/AllHours';
import useFeatureReq from '../react-query/useFeatureReq';
import TotalReqsCounter from '../components/AdminComponents/TotalReqCounter';
import AllRequestsCardList from '../components/AdminComponents/AllRequests';
import RequestDetail from '../components/AdminComponents/RequestDetail';

const UserRequestsPage = () => {
const [showPopup, setShowPopup] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const { users, verify, adminVerificationError } = useAdmin();
    const { requests, deleteFeatureReq, isLoading, isError, error } = useFeatureReq();
    console.log(requests);
    
    // Verify admin on mount
    useEffect(() => {
        const verifyAdmin = async () => {
        await verify();
        };
        verifyAdmin();
    }, []);

    // Show popup if there's an admin verification error
    useEffect(() => {
        if (adminVerificationError) {
        setShowPopup(true);
        }
    }, [adminVerificationError]);


    return ( 
        // <div className=style. style={{background:"#f4f7f6", minHeight:"100vh", padding:"20px"}}>
            <div className={styles.userRequestPageContainer} style={{ maxWidth: selectedRequest ? "100%" : undefined }}>
            {selectedRequest ? (
                <h1 style={{textAlign:"center"}}>Request Detail</h1>
            ) : (
                <h1 style={{textAlign:"center"}}>User Requests</h1>
)}
            {isLoading && <p>Loading...</p>}
            {isError && <p>Error: {error.message}</p>}
            {!isLoading && !isError && requests && !selectedRequest && (
                <>
                    <TotalReqsCounter usrFtReqs={requests}/>
                    <AllRequestsCardList selectRequest={setSelectedRequest} requests={requests} />
                </>
            )}
            {selectedRequest && (
                <RequestDetail 
                coachName={`${selectedRequest.coach.first_name} ${selectedRequest.coach.last_name}`}
                featureTitle={selectedRequest.title}
                featureBody={selectedRequest.body} 
                image_url={selectedRequest.coach.image_url}
                timestamp={selectedRequest.timestamp}
                deleteFunction={() => deleteFeatureReq(selectedRequest._id)}
                onClose={() => setSelectedRequest(null)} 
                />
            )}
            {showPopup && adminVerificationError && (
                <FailedPopup message={adminVerificationError} onClose={() => setShowPopup(false)} />
            )}
        </div>
     );
}
 
export default UserRequestsPage;