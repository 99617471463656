import React from 'react';
import '../../stylez/HoursWorkedList.css'
import CoachHoursCard from './CoachHoursCard';
function AllHoursCardList({ events }) {
  
console.log(events);

  return (
        <div className="hours-worked-cards-list">
          {events && Object.entries(events).map(([coachName, coachEvents]) => (
            <CoachHoursCard 
              key={coachName} 
              coachName={coachName} 
              eventCount={coachEvents.length} 
            />
          ))}
        </div>
      );
    }

export default AllHoursCardList;
