import React from 'react';
import '../../stylez/FeatureRequestCard.css'; // Assuming you will create a similar CSS file for styling
import { stripHtml } from '../../functions/Helpers';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

function FeatureRequestCard({ coachName, featureTitle, featureBody, timestamp, onClick }) {
  // Create a Date object from the timestamp
  console.log(timestamp);
  const date = new Date(timestamp);
  console.log(date);
  const now = new Date();

  // Check if the timestamp is from today
  const isToday = date.toDateString() === now.toDateString();

  
  const formatTimestampForToday = (timestamp) => {
    const timeZone = 'America/New_York';
    
    // Convert the timestamp to UTC (assuming the timestamp is in UTC+0)
    const utcDate = zonedTimeToUtc(timestamp, 'UTC');
    
    // Convert the UTC date to the desired timezone (e.g., EST/EDT for New York)
    const zonedDate = utcToZonedTime(utcDate, timeZone);
    
    // Check if the zoned date is today
    // if (isToday(zonedDate)) {
    if (isToday) {
      // If it's today, return just the time part
      return format(zonedDate, "h:mm a", { timeZone });
    } else {
      // If it's not today, return the full date
      return format(zonedDate, "MM/dd/yyyy", { timeZone });
    }
  };

    return (
      <div className="feature-request-card" onClick={onClick}>
        <div className="feature-header">
          <div className="feature-info">
            <span className="coach-name"><strong>Coach:</strong> {coachName}</span>
          </div>
          <span className="feature-time">{formatTimestampForToday(timestamp)}</span>
        </div>
        <h4 className="feature-title">{featureTitle}</h4>
        <p className="feature-body">{stripHtml(featureBody)}</p>
      </div>
    );
  }

export default FeatureRequestCard;
