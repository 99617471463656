import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetchWeeklyTimeSlots, fetchEventsForMonth, fetchEventsForBiWeekly, fetchEventsForCoachesBiWeekly, fetchEventsForWeek, fetchNxtEvent, fetchEventsForSingleDay, dropEvent, claimEvent } from '../services/api_data'; // Add fetchEventsForWeek
import { toast } from 'react-toastify';
import { extractMonthAndYear } from '../functions/Helpers';

export function useEvents({ month, year, startDate, endDate, fetchType = 'monthly' }) {
  const queryClient = useQueryClient();
  
  let fetcher, queryKey, queryArgs;

  switch (fetchType) {
    case 'nextEvent':
      fetcher = fetchNxtEvent;
      queryKey = ['nextEvent'];
      queryArgs = {};
      break;
    case 'biweekly':
      fetcher = fetchEventsForBiWeekly;
      queryKey = ['eventsForBiWeekly', startDate, endDate];
      queryArgs = { startDate, endDate };
      break;
    case 'weekly':
      fetcher = fetchEventsForWeek; // Add the new fetch function
      queryKey = ['eventsForWeek', startDate, endDate]; // Use appropriate query keys
      queryArgs = { startDate, endDate }; // Pass the necessary arguments
      break;
    case 'all':
      fetcher = fetchEventsForCoachesBiWeekly;
      queryKey = ['eventsForCoachesBiWeekly', startDate, endDate];
      queryArgs = { startDate, endDate };
      break;
    case 'singleDay':
      fetcher = fetchEventsForSingleDay;
      queryKey = ['eventsForSingleDay', startDate]; // Assuming `startDate` is the day you're fetching events for
      queryArgs = { date_str: startDate }; // This needs to match the fetcher function's expected parameter.
      break;      
    default:
      // 'monthly' and any other fetchType will default to monthly events
      fetcher = fetchEventsForMonth;
      queryKey = ['eventsForMonth', month, year];
      queryArgs = { month, year };
  }

  const { data, isLoading: eventsLoading, isError, error } = useQuery(
    queryKey,
    () => fetcher(queryArgs),
    {
      // enabled: fetchType === 'nextEvent' || fetchType === 'monthly' || fetchType === 'singleDay' || (!!startDate && !!endDate),
      enabled: fetchType === 'nextEvent' || fetchType === 'monthly' || fetchType === 'singleDay' || 
      (fetchType === 'biweekly' && !!startDate && !!endDate) || 
      (fetchType === 'all' && !!startDate && !!endDate) || 
      (fetchType === 'weekly' && !!startDate && !!endDate), // Enable query only when dates are provided
      retry: 1,
      staleTime: 0,
      cacheTime: 5 * 60 * 1000,
      onError: (error) => {
        if (error.response?.status === 404 && fetchType === 'nextEvent') {
                    queryClient.setQueryData('nextEvent', undefined);
        }
      },
    }
  );


  const dropEventMutation = useMutation(dropEvent, {
    onMutate: async ({cur_el, month, year}) => {
        console.log('cur_el', cur_el)


        // Cancel any outgoing refetches for the specific query
        await queryClient.cancelQueries(['eventsForWeek', startDate, endDate]);

        // Snapshot the previous value
        const prevEvents = queryClient.getQueryData(['eventsForWeek', startDate, endDate]);

        // Optimistically remove the event from the cache
        const newEvents = removeDroppedTimeslot(prevEvents, cur_el);

        queryClient.setQueryData(['eventsForWeek', startDate, endDate], newEvents);
        
        return { prevEvents };
    },
    onError: (error, eventId, context) => {
        // Rollback to the previous value
        queryClient.setQueryData(['eventsForWeek', startDate, endDate], context.prevEvents);
        toast.error('Oops! There was a problem dropping the event.');
    },
    onSuccess: (data) => {
        toast.success('Event dropped successfully.');            
        // Refetch the timeslots
        queryClient.invalidateQueries(['eventsForWeek', startDate, endDate]);
    },
});


  const dropClaimedEvent = async (cur_el, month, year) => {
    try {
        await dropEventMutation.mutateAsync({cur_el, month, year});
    } catch (error) {
        console.error('Failed to drop timeslot:', error);
        throw error;
    }
};

const claimMutation = useMutation(claimEvent, {
  onMutate: async ({ classType, recurring, startTime, endTime, firstName, id }) => {
      const {year, month} = extractMonthAndYear(startTime);
      // console.log('dateKey', dateKey)
      // Cancel any outgoing refetches for the specific query
      // console.log('QK', ['eventsForMonth', month, year])
      // await queryClient.cancelQueries(['eventsForMonth', month, year]);
      await queryClient.cancelQueries(['eventsForWeek', startDate, endDate]);

      // Snapshot the previous value
      // const prevEvents = queryClient.getQueryData(['eventsForMonth', month, year]);
      const prevEvents = queryClient.getQueryData(['eventsForWeek', startDate, endDate]);

      const newEvents = updateClaimedTimeslot(prevEvents, { startTime, endTime }, firstName)
      
      // console.log('newEvents', newEvents)


      queryClient.setQueryData(['eventsForWeek', startDate, endDate], newEvents);
      // Optimistically update to the new value
      // queryClient.setQueryData(['eventsForMonth', month, year], (oldTimeSlots) => {
      //     // Modify oldTimeSlots to reflect the claimed timeslot
      //     console.log('QK', ['eventsForMonth', month, year])
      //     console.log('oldTimeSlots', oldTimeSlots)
      //     return updateClaimedTimeslot(oldTimeSlots, { startTime, endTime }, firstName);
      // });

      return { prevEvents };
  },
  onSuccess: () => {
      toast.success('Event claimed successfully.');
      // Refetch the timeslots using the correct query keys
      // queryClient.invalidateQueries(['eventsForMonth', month, year]);
      queryClient.invalidateQueries(['eventsForWeek', startDate, endDate]);
  },
  onError: (error, { startTime, endTime }, context) => {
      // Rollback to the previous value using the correct query keys
      // queryClient.setQueryData(['eventsForMonth', month, year], context.prevEvents);
      queryClient.setQueryData(['eventsForWeek', startDate, endDate], context.prevEvents);
      toast.error('Oops! There was a problem claiming the event.');
  },
});

const claimCoachEvent = async (classType, recurring, startTime, endTime, firstName, id = null) => {
  try {
    console.log('classType', classType)
    console.log('recurring', recurring)
    console.log('startTime', startTime)
    console.log('endTime', endTime)
    console.log('coachName', firstName)
      const response = await claimMutation.mutateAsync({
          classType,
          recurring,
          startTime,
          endTime,
          firstName,
          id
      });
      return response;
  } catch (error) {
      // This catch block might be redundant since onError is already handling errors
      console.error('Failed to claim timeslot:', error);
      throw error;
  }
};

// New query for weekly time slots
const { data: weeklyTimeSlotsData, isLoading: weeklyTimeSlotsLoading } = useQuery(
  ['weeklyTimeSlots'],
  fetchWeeklyTimeSlots,
  {
    enabled: fetchType === 'monthly',
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 10 * 60 * 1000, // 10 minutes
  }
);



const filterEvents = (events) => {
  return events.filter(event => {
    if (!event || !event.title) {
      // console.log('Problematic event:', event);
      return false;
    }
    // console.log(event);
    return event.title.trim() !== '';
  });
};

// Process the data based on its structure
const processEvents = (eventsData) => {
  if (Array.isArray(eventsData)) {
    // If it's an array, filter it directly
    return filterEvents(eventsData);
  } else if (eventsData && typeof eventsData === 'object' && !eventsData.hasOwnProperty('events') && !eventsData.hasOwnProperty('time_slots')) {
    // If it's an object (dict of lists), filter each list
    // For coaches data has events not timeslots
    const processed = {};
    Object.keys(eventsData).forEach(coachName => {
      const filteredEvents = filterEvents(eventsData[coachName]);
      if (filteredEvents.length > 0) {
        processed[coachName] = filteredEvents;
      }
    });
    return processed;
  } else if (eventsData && typeof eventsData === 'object' && eventsData.hasOwnProperty('events') && eventsData.hasOwnProperty('time_slots')) {
    // If it's an object with 'events' and 'time_slots' keys, filter the array at events.events
    // For schedule month data for scehdule page
    return {
        events: filterEvents(eventsData.events),
        timeSlots: eventsData.time_slots,
        exceptions: eventsData.exceptions,
        starts: eventsData.starts,
        stops: eventsData.stops,
        availableEvents: eventsData.unclaimed_events,
        weeklyTimeSlots: weeklyTimeSlotsData?.time_slots || [], // Add weekly time slots here
      };
  }else if (eventsData && typeof eventsData === 'object' && eventsData.hasOwnProperty('events')) {
    // If it's an object with 'events' key, filter the array at events.events
    return {events:filterEvents(eventsData.events), availableEvents: eventsData.unclaimed_events};
  }
  return [];
};


// Compute filtered data based on the structure of `data`
const filteredData = processEvents(data);

const output = {
    isLoading: eventsLoading || weeklyTimeSlotsLoading,
    isError,
    error,
    events: filteredData,
    dropClaimedEvent,
    claimCoachEvent,
  };

  if (fetchType === 'nextEvent') {
    output.event = data || null;
    }

  return output;
}



const updateClaimedTimeslot = (oldTimeSlots, { startTime, endTime }, firstName) => {
  // console.log('oldTimeSlots', oldTimeSlots)
  // Clone the old data to avoid direct state mutation
  let newTimeSlots = JSON.parse(JSON.stringify(oldTimeSlots));

  newTimeSlots.events = newTimeSlots.events.map(event => {
    if (event.startTime === startTime && event.endTime === endTime) {
      return { ...event, title: firstName };
    }
    return event;
  })
  return newTimeSlots;
};


const removeDroppedTimeslot = (oldTimeSlots, selected_event) => {
  let newTimeSlots = JSON.parse(JSON.stringify(oldTimeSlots));
  newTimeSlots.events = newTimeSlots.events.map(event => {
    if (event._id === selected_event._id) {
      return {
        ...event,
        title: 'OPEN',
        isUnclaiming: true,
      };
    }
    return event;
  });
  return newTimeSlots;
};