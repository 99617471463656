import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useAuth } from "../react-query/useAuth";
import { Link } from 'react-router-dom';
import '../stylez/TestSignIn.css';
import smallLogoImg from '../imgs/logo_size.jpg';
import logoImg from '../imgs/logo_size_500.jpg';

const SignInPage = () => {
    const { signIn, isSigningIn } = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const googleFormUrl = "https://docs.google.com/forms/d/e/1FAIpQLSeG_QwAZx_80AfiAhZNthL5YOkEhRG8dfMtf2OzK8UZvAYe2Q/viewform?usp=sf_link"; // Replace with your actual Google Form URL

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await signIn({ email, password });
        } catch (error) {
            console.error('Login error:', error);
        }
    };

    return (
        <div className="signin-container">
            <div className="logo-container">
                <img src={logoImg} alt="Logo" className="signin-logo" />
            </div>
            <div className="signin-form">
                <img src={smallLogoImg} alt="Small Logo" className="signin-small-logo" /> {/* Small logo above the form */}
                <h1>Sign In</h1>
                <Form onSubmit={handleSubmit}>
                <Form.Group controlId='email'>
                    <Form.Label style={{color:'white'}} className="form-label-background">Email</Form.Label>
                    <Form.Control
                        type='email'
                        placeholder='Enter your email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId='password'>
                    <Form.Label style={{color:'white'}} className="form-label-background">Password</Form.Label>
                    <Form.Control
                        type='password'
                        placeholder='Enter your password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        type='submit'
                        disabled={isSigningIn || !email || !password} // Add the condition here
                        style={{marginTop:"5px", marginRight:"10px"}}
                    >
                        {isSigningIn ? 'Signing in...' : 'Sign In'}
                    </Button>
                    {/* <Button as={Link} to='/signup' style={{marginTop:"5px", backgroundColor: 'red', borderColor: 'red'}}> */}
                    {/* Sign Up */}
                  {/* </Button> */}
                </div>
                </Form>
                {/* <button 
                onClick={() => window.open(googleFormUrl, '_blank')}
                style={{
                padding: '10px 20px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '16px',
                marginTop: '20px'
                }}
            >
                Take the Survey
            </button> */}
            </div>
        </div>
    );
};

export default SignInPage;
