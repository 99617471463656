import "../../stylez/Toggle.css"

const Toggle = ({onLabel, offLabel, checkFunc, onChange}) => {
    return ( 
        <div className="toggle-tc" style={{marginBottom:"5px"}}>
            <label className="switch-tc">
                <input type="checkbox" checked={checkFunc} onChange={onChange} />
                <div className="slider-tc round">
                    <div className="labels-container-tc" style={{ left: checkFunc ? '-8px' : '8px' }}>
                        <span className={`label-tc on-tc`}>{onLabel}</span>
                        <span className={`label-tc off-tc`}>{offLabel}</span>
                    </div>
                </div>
            </label>
        </div>
     );
}
 
export default Toggle;