import { useMutation, useQueryClient } from 'react-query';
import { claimEvent, dropEvent, createScheduleEvent, deleteEvent } from '../services/api_data'; // Import deleteEvent
import { toast } from 'react-toastify';
import { useAuth } from './useAuth';

const useScheduleMaker = () => {
    const queryClient = useQueryClient();
    const { userData } = useAuth();
    const today = new Date();
    // format today in yyyy-mm-dd format similar to this 2023-12-17
    let dateKey = today.getFullYear() + '-' + 
                    (today.getMonth() + 1).toString().padStart(2, '0') + '-' + 
                    today.getDate().toString().padStart(2, '0');
    const dropEventMutation = useMutation(dropEvent, {
        onMutate: async (cur_el) => {
            console.log('cur_el', cur_el)
            const d = Math.abs(5 - cur_el.nxt);
            const dStr = d.toString().padStart(2, '0');            
            // get the current date and time using datetime

            console.log('dateKey', dateKey)

            // Cancel any outgoing refetches for the specific query
            await queryClient.cancelQueries(['timeSlots', dateKey, 5]);

            // Snapshot the previous value
            const previousTimeSlots = queryClient.getQueryData(['timeSlots', dateKey, 5]);

            // Optimistically remove the event from the cache
            const newTimeSlots = removeDroppedTimeslot(previousTimeSlots, cur_el._id, { dateKey });


            queryClient.setQueryData(['timeSlots', dateKey, 5], newTimeSlots);
            
            queryClient.setQueryData(['timeSlots', dateKey, 5], (oldTimeSlots) => {
                // Modify oldTimeSlots to reflect the claimed timeslot
                console.log('QK', dateKey)
                console.log('oldTimeSlots', oldTimeSlots)
                return updateClaimedTimeslot(previousTimeSlots, { date: dateKey, startTime:cur_el.start, endTime:cur_el.end }, '');
            });
            
            return { previousTimeSlots };
        },
        onError: (error, eventId, context) => {
            // Rollback to the previous value
            queryClient.setQueryData(['timeSlots', dateKey, 5], context.previousTimeSlots);
            toast.error('Oops! There was a problem dropping the event.');
        },
        onSuccess: (data) => {
            toast.success('Event dropped successfully.');            
            // Refetch the timeslots
            queryClient.invalidateQueries(['timeSlots', dateKey, 5]);
        },
    });
    
    const dropTimeslot = async (cur_el) => {
        try {
            await dropEventMutation.mutateAsync(cur_el);
        } catch (error) {
            console.error('Failed to drop timeslot:', error);
            throw error;
        }
    };

    const claimMutation = useMutation(claimEvent, {
        onMutate: async ({ startTime, endTime, nxt }) => {

            // get the current date and time using datetime
            // const today = new Date();
            // // format today in yyyy-mm-dd format similar to this 2023-12-17
            // const dateKey = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            // console.log('startTime', startTime)
            // console.log('nxt', nxt)
            // const d = Math.abs(5 - nxt);
            // console.log('d', d)
            // const dStr = d.toString().padStart(2, '0');            
            // console.log('dStr', dStr)
            // // Extract the date from startTime
            // dateKey = startTime.split('T')[0];

            // // Replacing day of dateKey with the correct day for the QK
            // // dateKey = dateKey.slice(0,-2) + dStr

            // console.log('dateKey', dateKey)
            // // Cancel any outgoing refetches for the specific query
            // await queryClient.cancelQueries(['eventsForSingleDay', dateKey]);
    
            // // Snapshot the previous value
            // const previousTimeSlots = queryClient.getQueryData(['eventsForSingleDay', dateKey]);
    
            // // Optimistically update to the new value
            // queryClient.setQueryData(['eventsForSingleDay', dateKey], (oldEvents) => {
            //     // Modify oldTimeSlots to reflect the claimed timeslot
            //     console.log('QK', dateKey)
            //     console.log('OldEvents', oldEvents)
            //     return updateClaimedTimeslot(oldEvents, { date: dateKey, startTime, endTime }, userData.first_name);
            // });
    
            // return { previousTimeSlots };
        },
        onSuccess: () => {
            toast.success('Event claimed successfully.');
            // Refetch the timeslots using the correct query keys
            queryClient.invalidateQueries(['timeSlots', dateKey, 5]);
        },
        onError: (error, { startTime, endTime }, context) => {
            // Rollback to the previous value using the correct query keys
            queryClient.setQueryData(['timeSlots', dateKey, 5], context.previousTimeSlots);
            toast.error('Oops! There was a problem claiming the event.');
        },
    });
    
    const claimTimeslot = async (classType, recurring, startTime, endTime, nxt, id = null) => {
        try {
            const response = await claimMutation.mutateAsync({
                classType,
                recurring,
                startTime,
                endTime,
                nxt,
                id
            });
            return response;
        } catch (error) {
            // This catch block might be redundant since onError is already handling errors
            console.error('Failed to claim timeslot:', error);
            throw error;
        }
    };


    const createScheduleMutation = useMutation(createScheduleEvent, {
        onMutate: async (scheduleData) => {
            // Cancel any ongoing refetches
            await queryClient.cancelQueries(['eventsForSingleDay', dateKey]);
            console.log('scheduleData', scheduleData)
            // Extract the date from the scheduleData object
            dateKey = scheduleData.date.split('T')[0];
            console.log('dateKey', dateKey)
            // Snapshot the previous value
            const prevEventData = queryClient.getQueryData(['eventsForSingleDay', dateKey]);
            console.log('prevEventData', prevEventData)

            if (prevEventData) {   
                // Optimistically update the cache
                queryClient.setQueryData(['eventsForSingleDay', dateKey], (oldeEvents) => {
                    // Modify oldeEvents to include the new event
                    // This logic depends on your data structure and how you want to add the new event
                    // For example, you might want to push the new event to the appropriate array
                    return addNewEventToTimeSlots(oldeEvents, scheduleData);
                });

                return { prevEventData };
            }
        },
        onSuccess: () => {
            // Handle success - e.g., show success message, invalidate related queries, etc.
            toast.success('Event created successfully.');
            // Optionally invalidate relevant queries to fetch updated data
            // queryClient.invalidateQueries('relevantQueryKey');
            queryClient.invalidateQueries(['eventsForSingleDay', dateKey]);

        },
        onError: (error, newEventData, context) => {
            // Handle error - e.g., show error message
            queryClient.setQueryData(['eventsForSingleDay', dateKey], context.previousTimeSlots);
            console.log('error', error )
            toast.error(`Error creating schedule: ${error.response.data.detail}`);
        }
    });

    const handleCreateSchedule = async (classType, isRecurring, startTime, endTime, date, nxt, selectedDayNames) => {
        try {
            // Construct the schedule data object
            const scheduleData = { classType, isRecurring, startTime, endTime, date, nxt, selectedDayNames };
            // Trigger the mutation
            await createScheduleMutation.mutateAsync(scheduleData);
        } catch (error) {
            console.error('Error in handleCreateSchedule:', error);
        }
    };


    // Mutation for deleting an event
    const deleteEventMutation = useMutation(deleteEvent, {
        onMutate: async (variables) => {
            const { eventObj, all } = variables;


            // console.log('nxt', eventObj.nxt)
            // const d = Math.abs(5 - eventObj.nxt);
            // console.log('d', d)
            // const dStr = d.toString().padStart(2, '0');            
            // console.log('dStr', dStr)
            // // Extract the date from startTime
            dateKey = eventObj.start.split('T')[0];

            // // Replacing day of dateKey with the correct day for the QK
            // dateKey = dateKey.slice(0,-2) + dStr

            console.log('dateKey', dateKey)


            // Cancel any ongoing refetches that might affect this data
            // await queryClient.cancelQueries(['timeSlots', dateKey, 5]); // Adjust the query key as needed
    
            // // Snapshot the previous value
            const previousEventData = queryClient.getQueryData(['eventsForSingleDay', dateKey]); // Adjust the query key as needed
    
            // // Optimistically update to the new value
            queryClient.setQueryData(['eventsForSingleDay', dateKey], (oldEvents) => {
                if (eventObj.recurring && all) {
                    // Extract the time from the start property
                    // const eventTime = eventObj.start.split('T')[1];
                    // Remove all events with the same start time
                    return removeAllEventsWithStartTime(oldEvents, eventObj);
                } else {
                    // Remove the specific event
                    return removeEventFromTimeslots(oldEvents, eventObj._id);
                }
            });
    
            return { previousEventData };
        },
        onError: (error, variables, context) => {
            // Rollback to the previous value
            queryClient.setQueryData(['eventsForSingleDay', dateKey], context.previousTimeSlots); // Adjust the query key as needed
            toast.error('Error deleting the event.');
        },
        onSuccess: () => {
            toast.success('Event deleted successfully.');
            // Invalidate and refetch relevant queries to update with fresh data
            queryClient.invalidateQueries(['eventsForSingleDay', dateKey]); // Adjust the query key as needed
        },
    });

    const handleDeleteEvent = async (eventObj, all=false) => {
        try {
            await deleteEventMutation.mutateAsync({ eventObj, all });
        } catch (error) {
            console.error('Failed to delete event:', error);
            // Handle or throw the error
        }
    };
    
    return { claimTimeslot, ...claimMutation, dropTimeslot, ...dropEventMutation, handleCreateSchedule, ...createScheduleMutation, handleDeleteEvent, ...deleteEventMutation};
};

const updateClaimedTimeslot = (oldTimeSlots, { date, startTime, endTime }, firstName) => {
    // Clone the old data to avoid direct state mutation
    let newTimeSlots = JSON.parse(JSON.stringify(oldTimeSlots));

    // Loop through the pages and events to find the correct timeslot to update
    newTimeSlots.pages.forEach(page => {
        page.events.forEach(eventGroup => {
            if (eventGroup.date.startsWith(date)) {  // Checking if the date matches
                eventGroup.slots.forEach(slot => {
                    if (slot.start === startTime && slot.end === endTime) {
                        // Here, update the slot as claimed. For example:
                        slot.title = firstName; // Or use the user's first name if available
                    }
                });
            }
        });
    });

    return newTimeSlots;
};

const removeDroppedTimeslot = (oldTimeSlots, eventId, { dateKey }) => {
    // Clone the old data to avoid direct state mutation
    let newTimeSlots = JSON.parse(JSON.stringify(oldTimeSlots));

    // Loop through the pages and events to find and remove the correct timeslot
    newTimeSlots.pages.forEach(page => {
        page.events.forEach(eventGroup => {
            if (eventGroup.date.startsWith(dateKey)) {
                // Filter out the slot with the matching eventId
                eventGroup.slots = eventGroup.slots.filter(slot => slot._id !== eventId);
            }
        });
    });

    return newTimeSlots;
};

// Helper function to remove an event from timeslots
const removeEventFromTimeslots = (oldTimeSlots, eventId) => {
    // Clone the old data to avoid direct state mutation
    let newTimeSlots = JSON.parse(JSON.stringify(oldTimeSlots));

    // Logic to remove the event with the given eventId from newTimeSlots
    // Adjust the logic to fit your data structure
    // newTimeSlots.pages.forEach(page => {
    //     page.events.forEach(eventGroup => {
    //         eventGroup.slots = eventGroup.slots.filter(slot => slot._id !== eventId);
    //     });
    // });
    console.log('Check this out')
    console.log(eventId)
    // Logic to remove the event with the given eventId from 'events'
    newTimeSlots.events = newTimeSlots.events.filter(event => {
        if (event._id !== eventId){
            
            return true
        }else{
            console.log('event removed')
            console.log(event)
            return false
        }
        // console.log()
    });

    return newTimeSlots;
};

// Helper function to remove all events with the same start time
const removeAllEventsWithStartTime = (oldTimeSlots, newEventObj) => {
    // Clone the old data to avoid direct state mutation
    let newTimeSlots = JSON.parse(JSON.stringify(oldTimeSlots));
    console.log('Check this out')
    console.log(oldTimeSlots)
    // // Logic to remove all events with the given start time
    // newTimeSlots.pages.forEach(page => {
    //     page.events.forEach(eventGroup => {
    //         eventGroup.slots = eventGroup.slots.filter(slot => {
    //             const slotTime = slot.start.split('T')[1];
    //             return slotTime !== eventTime;
    //         });
    //     });
    // });
        let eventTime = newEventObj.start.split('T')[1];
        let eventType = newEventObj.type;
        // Logic to remove all events with the given start time from 'events'
        newTimeSlots.events = newTimeSlots.events.filter(event => {
            const eventStartTime = event.start.split('T')[1];
            return !(eventStartTime === eventTime && event.type === eventType);
        });
    
        // Logic to remove all events with the given start time from 'unclaimed_events'
        newTimeSlots.unclaimed_events = newTimeSlots.unclaimed_events.filter(event => {
            const eventStartTime = event.start.split('T')[1];
            return !(eventStartTime === eventTime && event.type === eventType);
        });
    
        return newTimeSlots;

    // return newTimeSlots;
};

// Helper function to add a new event to timeslots
const addNewEventToTimeSlots = (oldEvents, newEvent) => {
    // Clone the old data to avoid direct state mutation
    let newEvents = JSON.parse(JSON.stringify(oldEvents));

    // Extract the date from newEvent
    const eventDate = newEvent.date.split('T')[0];

    // Construct start and end timestamps for the new timeslot
    const startTimestamp = `${eventDate}T${newEvent.startTime}:00`;
    const endTimestamp = `${eventDate}T${newEvent.endTime}:00`;


    // Create a new timeslot object in the correct format
    newEvent = {
        start: startTimestamp,
        end: endTimestamp,
        nxt: newEvent.nxt,
        pay_period: 555, // Set this if needed
        recurring: newEvent.isRecurring,
        title: "", // Assuming classType is the title
        type: newEvent.classType // Set this if needed
    };

    newEvents.unclaimed_events.push(newEvent);

    return newEvents;
};

export default useScheduleMaker;