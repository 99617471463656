import { parseISO, format } from 'date-fns';
import { da } from 'date-fns/locale';

export const getISODate = () => {
    // Get current date and time
    let currentDate = new Date();

    // Set time to start of the day (midnight)
    currentDate.setHours(0, 0, 0, 0);

    // Convert to ISO string
    let isoString = currentDate.toISOString();
    return isoString;
};


// Utility function to remove HTML tags from a string
export function stripHtml(htmlString) {
    // Create a new div element
    const tempDiv = document.createElement("div");
    // Set the HTML content with the provided
    tempDiv.innerHTML = htmlString;
    // Retrieve the text property of the element (innerText gives just the text, not the HTML)
    return tempDiv.innerText;
}


export const formatDateTime = (dateTimeStr) => {
    // console.log(dateTimeStr)
    const date = parseISO(dateTimeStr);
    return format(date, 'hh:mm a');
};


export function findCompleteEventDays(monthlyEvents, timeSlots, exceptions, starts, stops) {
    const daysOfMonthWithCompleteEvents = new Set();

    if (monthlyEvents.length === 0) {
        return Array.from(daysOfMonthWithCompleteEvents);
    }

    // Extract year and month from the first event
    const firstEventDate = new Date(monthlyEvents[0].start);
    const year = firstEventDate.getFullYear();
    const month = firstEventDate.getMonth();

    // Adjust day of week to be 0-indexed starting from Monday
    function adjustDayOfWeek(day) {
        return (day === 0) ? 6 : day - 1;
    }

    function formatTimeSlot(timeSlot) {
        // Remove the leading 'T', split by colon, and convert to 12-hour format with AM/PM
        let [hours, minutes] = timeSlot.slice(1).split(':');
        hours = parseInt(hours, 10);
        const amPm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours === 0 ? 12 : hours; // Convert hour '0' to '12' for 12-hour clock
        return `${hours.toString().padStart(2, '0')}:${minutes} ${amPm}`;
    }

    // Group events by day of month
    const eventsByDay = {};
    monthlyEvents.forEach(event => {
        const eventDate = new Date(event.start);
        const dayOfMonth = eventDate.getDate();
        const dayOfWeek = adjustDayOfWeek(eventDate.getDay()); // Adjusted day of week  

        if (!eventsByDay[dayOfMonth]) {
            eventsByDay[dayOfMonth] = new Set();
        }
        const eventTime = event.start.split('T')[1]; // Extracting time part
        const startDate = new Date(event.start);

        const formattedTime = format(startDate, 'hh:mm a');
        console.log('eventTime from helper > findCompleteEventDats()', formattedTime);

        // eventsByDay[dayOfMonth].add("T" + eventTime);
        eventsByDay[dayOfMonth].add(formattedTime);
    });

    // Check each day for completeness
    Object.entries(eventsByDay).forEach(([day, eventTimes]) => {
        const dayOfWeek = adjustDayOfWeek(new Date(year, month, day).getDay());
        const date = new Date(year, month, day).toISOString().split('T')[0]; // ISO string format of the day

        console.log(`Checking day: ${day}, dayOfWeek: ${dayOfWeek}, eventTimes:`, Array.from(eventTimes));

        // Check if all time slots for this day of the week are filled or have an exception
        const allTimeSlotsFilledOrExcepted = timeSlots[dayOfWeek].every(timeSlot => {
            // if (day == 1 && dayOfWeek == 0){
            //     console.log('TimeSlots for first on july')
            //     console.log(timeSlots[dayOfWeek])
            // } 
            const formattedTimeSlot = formatTimeSlot(timeSlot);
            const isTimeSlotFilled = eventTimes.has(formattedTimeSlot);

            // If the time slot is an object with exceptions, check if the day is in the exception list
            // const isDayExcepted = typeof timeSlot === 'object' && timeSlot.except.includes(date);
            const isDayExcepted = exceptions[formattedTimeSlot].includes(date);


            // Add in checks to a stops and starts list argument
            if (formattedTimeSlot in starts) {
                const startDate = starts[formattedTimeSlot];
                if (startDate > date) {
                // The start time for this timeslot has not come yet and is after the current day
                // The day is an exception, do not render this timeslot this early on the calendar
                    return true;
                }
            }

            if (formattedTimeSlot in stops) {
                const stopDate = stops[formattedTimeSlot];
                if (stopDate <= date) {
                    // The stop date for this timeslot has already come and the timeslot should no longer be valid
                    // The day is an exception, do not render this timeslot
                    return true; // This will count as filled or excepted
                }
            }

            // ! - Add in checks to a stops and starts list argument
            // ! - The starts list value will have each formattedTimeSlot as key and their start date in 'yyyy-mm-dd' format 
            // ! - If the formamtedTimeSlot in the starts list value is before the date of the current iteration then the current day is not an execption for this timeslot and should be counted towards filled or not filled 
            // ! - If starts[formamtedTimeSlot] < date then the day is not an execption a user should select this ( the start time for this timeslot already came and is before the current day)
            // ! - If starts[formamtedTimeSlot] > date the the day is an execption and the timeslot should not be rendered this early on calendar ( the starty time for this timeslot has not came yet and is after the current day)
            // ! - If the formattedTimeSlot in the stops list value is before the current  iteration then the current day is an exception becuase the stop date for that timeslot already came and should no longer be a valid timeslot on the calendar
            // ! - If stops[formamtedTimeSlot] < date means stop date has already came and the timeslot does not need to be counted for filled or no tfilled into the future from thsi date on.
            // ! - If stops[formamtedTimeSlot] > date means stop date is not passed yet and the timeslot is still avtive and valid for this day on the calendar it should be taken into account for the filled or not filled 
            

            console.log(date)
            console.log(typeof date)
            if (date == "2024-05-16"){
                console.log(timeSlots)
            }
            console.log(`Time Slot: ${formattedTimeSlot}, Filled: ${isTimeSlotFilled}, Excepted: ${isDayExcepted}`);

            return isTimeSlotFilled || isDayExcepted;
        });

        if (allTimeSlotsFilledOrExcepted) {
            daysOfMonthWithCompleteEvents.add(parseInt(day));
        }
    });

    return Array.from(daysOfMonthWithCompleteEvents);
}

export function findCoachingDays(monthlyEvents, coachName) {
    const coachingDays = new Set();

    if (monthlyEvents.length === 0) {
        return Array.from(coachingDays);
    }

    // Group events by day of month
    const eventsByDay = {};
    monthlyEvents.forEach(event => {
        if (event.title === coachName) {
            const eventDate = new Date(event.start);
            const dayOfMonth = eventDate.getDate();

            if (!eventsByDay[dayOfMonth]) {
                eventsByDay[dayOfMonth] = new Set();
            }
            const eventTime = event.start.split('T')[1]; // Extracting time part
            eventsByDay[dayOfMonth].add("T" + eventTime);
        }
    });

    // Add days with coaching events to the set
    Object.keys(eventsByDay).forEach(day => {
        coachingDays.add(parseInt(day));
    });

    return Array.from(coachingDays);
}

export function getWeekdayFromString(dateStr) {
    const date = new Date(dateStr);
    const options = { weekday: 'long' };
    const weekdayFullName = date.toLocaleDateString('en-US', options);
    return weekdayFullName;
  }
  

export function extractMonthAndYear(dateString) {
    console.log('dateString', dateString);
    const date = new Date(dateString);
    const year = date.getFullYear(); // Gets the year (4 digits)
    const month = date.getMonth() + 1; // Gets the month, from 0 to 11. Add 1 to get 1 to 12
    
    return { year, month };
  }