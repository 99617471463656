import React, { useState,useEffect } from 'react';
// import './UserDetailCard.css'; // Make sure to import the CSS file
import '../../stylez/UserDetailCard.css';
import ProfilePictureViewer from '../SettingsComponents/PFPViewer';
import { fetchImage } from '../../services/api_data';
// import default_pfp from '../../../imgs/muscle_default.svg'
import default_pfp from '../../imgs/muscle_default.svg'
import { useAdmin } from '../../react-query/useAdmin';

const UserDetailCard = ({coach}) => {
  const [isAdmin, setIsAdmin] = useState(coach.is_admin);
  const [pfp, setPfp] = useState(default_pfp);
  const { updateUserAdminStatus } = useAdmin();

  const toggleAdminStatus = () => {
const previousAdminStatus = isAdmin;

    setIsAdmin(!isAdmin);
    // Attempt to update the admin status
        updateUserAdminStatus({
          userId: coach.id,
          isAdmin: !isAdmin,
        }, {
          // If there's an error, revert the admin status back to the original
          onError: () => setIsAdmin(previousAdminStatus),
        });
  };
  
  useEffect(() => {
    const getProfilePicture = async () => {
      try {
        const response = await fetchImage(coach.image_url);
        setPfp(response);
      } catch (error) {
        console.error('An error occurred while fetching the profile picture:', error);
      }
    }

    if (coach.image_url) {
      console.log(coach.first_name);
      console.log("Fetching profile picture");
      getProfilePicture();
    }
  }, [coach]);

  return (
    <div className="user-detail-card">
      <div className="profile-section">
        {/* <div className="profile-placeholder"></div> */}
        <ProfilePictureViewer src={pfp} size={100} alt="Coach Profile Picture" />
        <div className="name">{coach.first_name} {coach.last_name}</div>
      </div>
      <div className="admin-toggle">
        <label className="switch">
          <input type="checkbox" checked={isAdmin} onChange={toggleAdminStatus} />
          <span className="slider round"></span>
        </label>
        <div className="status-label">{isAdmin ? 'Admin' : 'Not Admin'}</div>
      </div>
    </div>
  );
};

export default UserDetailCard;
