import React, { useEffect } from 'react';
import '../../stylez/Popup.css'; // Assuming this is the path to your CSS file

const DeletePopup = ({ onClose, onDeleteOne, onDeleteAll }) => {
    
    useEffect(() => {
        const handleEscapeKey = (event) => {
          if (event.key === 'Escape') {
            onClose();
          }
        };
    
        document.addEventListener('keydown', handleEscapeKey);
    
        return () => {
          document.removeEventListener('keydown', handleEscapeKey);
        };
      }, [onClose]);
    
      return (
        <div className="popup-background">
          <div className="popup-container delete-popup">
            <div className="popup-content">
              <p>Do you want to delete all items or just one?</p>
              <button className="home-button" onClick={onDeleteOne}>Delete One</button>
              <button className="home-button" onClick={onDeleteAll}>Delete All</button>
              <button className="home-button" onClick={onClose}>Cancel</button>
            </div>
          </div>
        </div>
      );
    };

export default DeletePopup;