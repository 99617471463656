import React, { useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css'; // import styles
import useFeatureReq from '../../react-query/useFeatureReq';

const FeatureRequest = () => {
    const [featureTitle, setFeatureTitle] = useState("");
    const [featureBody, setFeatureBody] = useState("");
const [titleError, setTitleError] = useState(false); // New state for title error
    const { createFeatureRequest, isLoading, isError, error } = useFeatureReq();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setTitleError(false); // Reset title error state on new submission
        await createFeatureRequest(featureTitle, featureBody, setTitleError);
    };


    return (
        <Container>
            <h1 style={{ textAlign: "center" }}>Feature Request</h1>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="featureTitle">
                    <Form.Label>Feature Title</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter the title of the feature"
                        value={featureTitle}
                        onChange={(e) => setFeatureTitle(e.target.value)}
                        style={titleError ? { border: '1px solid red' } : {}} // Apply inline style conditionally
                    />
                </Form.Group>
                <Form.Group controlId="featureBody">
                    <Form.Label>Describe the Feature</Form.Label>
                    <textarea
                        className="form-control" // Using Bootstrap's form-control class for styling
                        placeholder="Describe your feature request here"
                        value={featureBody} 
                        onChange={(e) => setFeatureBody(e.target.value)}
                        rows="4" // Adjust the number of rows as needed
                    />
                </Form.Group>
                <Button type="submit" style={{marginTop:"10px"}}>Submit Feature Request</Button>
            </Form>
        </Container>
    );
}

export default FeatureRequest;
