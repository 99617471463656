// import styles from "../../stylez/FeatureRequestDeatil.module.css";
// import "../../stylez/FeatureRequestDetail.css";
import styles from "../../stylez/FeatureRequestDetail.module.css";
import ProfilePictureViewer from "../SettingsComponents/PFPViewer";
import default_pfp from "../../imgs/muscle_default.svg";
import { fetchImage } from "../../services/api_data";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { parseISO, format } from 'date-fns';
import {format, utcToZonedTime, zonedTimeToUtc  } from 'date-fns-tz';


const RequestDetail = ({coachName, featureTitle, featureBody, image_url=null, timestamp, onClose, deleteFunction}) => {
    const [pfp, setPfp] = useState(default_pfp);
    const nav = useNavigate();
    useEffect(() => {
        const getProfilePicture = async () => {
          try {
            const response = await fetchImage(image_url);
            setPfp(response);
          } catch (error) {
            console.error('An error occurred while fetching the profile picture:', error);
          }
        }
    
        if (image_url) {
        //   console.log(.first_name);
          console.log("Fetching profile picture");
          getProfilePicture();
        }
      }, [image_url]);



      const formatTimestamp = (timestamp) => {
        const timeZone = 'America/New_York';
        
        // Ensure the timestamp is treated as UTC before conversion
        const utcDate = zonedTimeToUtc(timestamp, 'UTC');
        console.log('UTC Date:', utcDate.toISOString());
        
        // Convert the UTC date to the desired timezone
        const zonedDate = utcToZonedTime(utcDate, timeZone);
        console.log('Zoned Date:', zonedDate.toString());
      
        // Format the zoned date
        const formattedDate = format(zonedDate, "MM/dd/yyyy h:mm a", { timeZone });
        console.log('Formatted Date:', formattedDate);
      
        return formattedDate;
      };
      // const formattedDate = format(zonedDate, "MM/dd/yyyy h:mm a", { timeZone });
      

      
      const handleDelete = () => {
        // Add your logic for deleting the feature request
        // Redirect to the previous page after deletion
        // history.goBack();
        deleteFunction();
        // nav(-1);
      };

    return ( 
        <div className={styles.requestDetailContainer}>
            <div className={styles.requestDetailCard}>
                <div className={styles.timestampContainer}>
                  {formatTimestamp(timestamp)}
                </div>
                {/* <h1>Request Detail</h1> */}
                <ProfilePictureViewer src={pfp} size={100} alt="Coach Profile Picture" />
                <div className={styles.requestDetailContent}>
                    <h3>{featureTitle}</h3>
                    <h3>Coach: {coachName}</h3>
                    <p>{featureBody}</p>
                </div>
            </div>
            <div className={styles.buttonsContainer}>
        <button onClick={handleDelete} className={styles.deleteButton}>Delete</button>
        <button onClick={() => onClose()} className={styles.backButton}>Back</button>
      </div>
    </div>
     );
}
 
export default RequestDetail;
