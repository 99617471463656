import React, { useEffect, useState } from 'react';
import '../../stylez/TotalHoursCounter.css';  // Import the CSS (You will have to create this file)

const TotalReqsCounter = ({ usrFtReqs }) => {
    const [totalRequests, setTotalRequests] = useState(0);


    useEffect(() => {
        if (usrFtReqs) {
            setTotalRequests(usrFtReqs.length);
        }
    }, [usrFtReqs]); // Only re-run the effect if events or type changes

    return (
        <div className="total-hours-counter">
            <h2>Total Requests: {totalRequests}</h2>
        </div>
    );
};

export default TotalReqsCounter;
