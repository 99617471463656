import React, { useState, useRef, useEffect } from 'react';
import '../stylez/AddClassPage.css';
import { Card, ButtonGroup, Button } from 'react-bootstrap';
import useScheduleMaker from '../react-query/useScheduleMaker';
import EventList from '../components/ScheduleComponents/EventList';
import { useEvents } from '../react-query/useEvents';


const classTypes = {
  crossfit: { name: 'CrossFit', color: 'bg-custom-red' },
  onRamp: { name: 'On Ramp', color: 'bg-custom-blue' },
  barbell: { name: 'Barbell', color: 'bg-custom-purple' },
  openGym: { name: 'Open Gym', color: 'bg-custom-green' },
  hero: { name: 'Hero', color: 'bg-custom-yellow' },
  other: { name: 'Other', color: 'bg-custom-orange' }
};


const AddClassPage = () => {
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); // Format to YYYY-MM-DD
  const {handleCreateSchedule, handleDeleteEvent} = useScheduleMaker();
  const { events, isLoading, isError } = useEvents({ startDate: selectedDate, fetchType: 'singleDay' });
  const [selectedButton, setSelectedButton] = useState(null);
  const [selectedDays, setSelectedDays] = useState(new Array(7).fill(false)); // Track selected days
  const inputContainerRef = useRef(null);
  const [singleClassDetails, setSingleClassDetails] = useState({
    classType: '',
    date: '',
    startTime: '',
    endTime: '',
  });
  const [recurringClassDetails, setRecurringClassDetails] = useState({
    classType: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
  });
  const reoccurringInputContainerRef = useRef(null); // Ref for reoccurring inputs
  
  const todaysEvents = events?.events ?? [];
  const available_events = events?.availableEvents ?? [];

  const combinedEvents = [...todaysEvents, ...available_events];

  const handleDateChange = async (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);
    // await fetchEventsForDate(newDate);
  };
  
  
  const handleSingleClassInputChange = (e) => {
    setSingleClassDetails({
      ...singleClassDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleRecurringClassInputChange = (e) => {
    setRecurringClassDetails({
      ...recurringClassDetails,
      [e.target.name]: e.target.value,
    });
  };

  const formatDateForBackend = (dateString) => {
    // Append a default time and timezone to match the backend's expected format
    // For example, appending "T00:00:00.000Z" sets the time to midnight UTC
    return `${dateString}T00:00:00.000Z`;
  };
  
  // Usage example before submitting:
  
  // Add submit function for single class
  // const submitSingleClass = () => {
  //   console.log('Single Class Details:', singleClassDetails);
  // };
  const submitSingleClass = async () => {
    try {
      const { classType, date, startTime, endTime } = singleClassDetails;
      // Assuming nxt needs to be set, but source for this value isn't provided in your code
      const nxt = 0; // Placeholder, adjust as necessary
      // await handleCreateSchedule(classType, false, startTime, endTime, date + "T00:00:00.000Z", nxt, []);
      await handleCreateSchedule(classType, false, startTime, endTime, date, nxt, []);
      console.log('Single Class Submitted');
    } catch (error) {
      console.error('Error submitting single class:', error);
    }
  };

  
  // Add submit function for recurring class
  // const submitRecurringClass = () => {
  //   console.log('Recurring Class Details:', recurringClassDetails, 'Selected Days:', selectedDays);
  // };
  const submitRecurringClass = async () => {
    try {
      const { classType, startDate, startTime, endTime } = recurringClassDetails;
      // const dateForBackend = formatDateForBackend(recurringClassDetails.startDate);
      // Assuming startTime and endTime are in "HH:MM" format and startDate is in ISO string format
      // Convert selectedDays from boolean array to day names array matching your backend expectation
      const selectedDayNames = selectedDays.reduce((acc, isSelected, index) => {
        if (isSelected) acc.push(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][index]);
        return acc;
      }, []);
  
      await handleCreateSchedule(
        classType.toLowerCase(), // Ensure class type is in lowercase as your backend might expect
        true, // Recurring set to true
        startTime,
        endTime,
        // dateForBackend,
        startDate,
        0, // 'nxt' field, ensure you understand its purpose and provide a value if needed
        selectedDayNames
      );
      console.log('Recurring Class Submitted');
    } catch (error) {
      console.error('Error submitting recurring class:', error);
    }
  };
  
  const deleteEvent = async (event, all) => {
    try {
      await handleDeleteEvent(event, all);
      console.log('Event deleted:', event);
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };


  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const handleClickOutside = (event) => {
    if (
      inputContainerRef.current &&
      !inputContainerRef.current.contains(event.target) &&
      reoccurringInputContainerRef.current &&
      !reoccurringInputContainerRef.current.contains(event.target) &&
      !event.target.classList.contains('class-button') &&
      !(event.target.type === 'date') && // Add this line
      !event.target.closest('.event-card') && // Add this line
      !event.target.closest('.popup-background') // Add this line
    ) {
      setSelectedButton(null);
    }
  };
  

  const toggleDay = (event, index) => {
    // Function to toggle day selection
    const newSelectedDays = [...selectedDays];
    newSelectedDays[index] = !newSelectedDays[index];
    setSelectedDays(newSelectedDays);
  };

  const renderDayButtons = () => {
    const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    return days.map((day, index) => (
      // Assuming you have a Button component, or replace with standard button
      <Button 
        key={`${day}-${index}`} // Modified key to be unique for each button
        // className={`day-button ${selectedDays[index] ? "primary" : "secondary"}`}
        variant={selectedDays[index] ? 'primary' : 'secondary'}
        disabled={selectedButton !== 'reoccurring'}
        onClick={(e) => toggleDay(e, index)}>
        {day}
      </Button>
    ));
  };
  
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [inputContainerRef, reoccurringInputContainerRef]); // Add any other dependencies if needed


  useEffect(() => {
    if (selectedButton === 'delete') {
      // console.log('Fetching events for date:', selectedDate);
      // console.log('Events:', combinedEvents)
      // fetchEventsForDate(selectedDate);
    }
  }, [selectedButton, selectedDate]); // Re-fetch if the selected button or date changes

  
  return (
    <div className="add-class-page" onClick={handleClickOutside}>
      <h1>Class Manager</h1>
      <button
        className={`class-button ${selectedButton === 'single' ? 'selected' : ''}`}
        onClick={() => handleButtonClick('single')}
      >
        Single Class
      </button>
      <div
        ref={inputContainerRef}
        className={`input-container ${selectedButton === 'single' ? 'show' : ''}`}
      >
        {/* Single Class Inputs */}
        {/* <input type="text" name="classType" placeholder="Class Type" onChange={handleSingleClassInputChange} /> */}
        <select name="classType" onChange={handleSingleClassInputChange} value={singleClassDetails.classType}>
          <option value="" disabled>Select Class Type</option>
          {Object.entries(classTypes).map(([key, { name }]) => (
            <option key={key} value={key}>
              {name}
            </option>
          ))}
        </select>
        <input type="date" name="date" placeholder="Date" onChange={handleSingleClassInputChange} />
        <input type="time" name="startTime" placeholder="Start Time" onChange={handleSingleClassInputChange} />
        <input type="time" name="endTime" placeholder="End Time" onChange={handleSingleClassInputChange} />
        <button className="add-button" onClick={submitSingleClass}>Add</button>
      </div>
      <div className="or-divider">OR</div>
      <button
        className={`class-button ${selectedButton === 'reoccurring' ? 'selected' : ''}`}
        onClick={() => handleButtonClick('reoccurring')}
      >
        Reoccurring Class
      </button>
      <div
        ref={reoccurringInputContainerRef}
        className={`input-container ${selectedButton === 'reoccurring' ? 'show' : ''}`}
      >
        {/* Reoccurring Class Inputs */}
        {/* Include any additional inputs specific to a reoccurring class */}
        {/* <input type="text" placeholder="Frequency (e.g., Weekly, Monthly)" /> */}
        {/* <input type="text" name="classType" placeholder="Class Type" onChange={handleRecurringClassInputChange} /> */}
        <select name="classType" onChange={handleRecurringClassInputChange} value={recurringClassDetails.classType}>
          <option value="" disabled>Select Class Type</option>
          {Object.entries(classTypes).map(([key, { name }]) => (
            <option key={key} value={key}>
              {name}
            </option>
          ))}
        </select>
        <input type="date" name="startDate" placeholder="Start Date" onChange={handleRecurringClassInputChange} />
        {/* <input type="date" name="endDate" placeholder="End Date" onChange={handleRecurringClassInputChange} /> */}
        <input type="time" name="startTime" placeholder="Start Time" onChange={handleRecurringClassInputChange} />
        <input type="time" name="endTime" placeholder="End Time" onChange={handleRecurringClassInputChange} />
        <div className="weekday-picker">
          {/* <ButtonGroup size="sm" className="weekday-picker"> */}
              {renderDayButtons()}
          {/* </ButtonGroup> */}
          {/* {renderDayButtons()} */}
        </div>
        <button className="add-button" onClick={submitRecurringClass}>Add</button>
      </div>
      <div className="or-divider">OR</div> {/* Add this divider */}
      <button
        className={`class-button ${selectedButton === 'delete' ? 'selected delete' : ''}`}
        onClick={() => handleButtonClick('delete')}
      >
        Delete Class
      </button>
      {selectedButton === 'delete' && (
            <div className={`input-container ${selectedButton === 'delete' ? 'show' : ''}`}>
            <input 
              type="date" 
              value={selectedDate} 
              onChange={handleDateChange}
              className="date-input" // Add a class for styling
            />
            <EventList 
              events={combinedEvents} 
              view="Delete"
              dropClaimedEvent={deleteEvent}
            />
          </div>
        )}
    </div>
  );
};

export default AddClassPage;
