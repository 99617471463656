import { useMutation, useQueryClient } from 'react-query';
import { uploadFile } from '../services/api_data';
import { toast } from 'react-toastify';


const useProgUpload = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation(uploadFile, {
        onSuccess: () => {
            // queryClient.invalidateQueries('files');
            toast.success('Programming uploaded successfully');
        }, 
        onError: (error) => {
            toast.error('Error uploading programming', error.message);
            console.error('Error uploading file:', error);
        }
    });


    // return useMutation(uploadFile);
    return mutation;

};

export default useProgUpload;

