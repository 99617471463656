import React, { useEffect, useState } from 'react';
import '../../stylez/TotalHoursCounter.css';  // Import the CSS (You will have to create this file)

const TotalHoursCounter = ({ events, type='single' }) => {
    // let totalHours = 0;
const [totalEventHours, setTotalEventHours] = useState(0);


    useEffect(() => {
        if (type === 'all') {
            const totalEvents = Object.values(events).reduce((acc, coachEvents) => {
                return acc + coachEvents.length;
            }, 0);
            setTotalEventHours(totalEvents);
        } else {
    if (events) {
        setTotalEventHours(events.length);
            }
    }
}, [events, type]); // Only re-run the effect if events or type changes

    return (
        <div className="total-hours-counter">
            <h2>Total Hours: {totalEventHours}</h2>
        </div>
    );
};

export default TotalHoursCounter;
