// components/SurveyModal.js
import React from 'react';
import Modal from './Modal';

const SurveyModal = ({ isOpen, onClose }) => {
  const googleFormUrl = "https://docs.google.com/forms/d/e/1FAIpQLSeG_QwAZx_80AfiAhZNthL5YOkEhRG8dfMtf2OzK8UZvAYe2Q/viewform?usp=sf_link"; // Replace with your actual Google Form URL

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="We Value Your Feedback!">
      <p>We're constantly working to improve our app and would love to hear your thoughts. Please take a moment to fill out our quick survey.</p>
      <p>Your feedback is crucial in helping us enhance your experience!</p>
      <button 
        onClick={() => window.open(googleFormUrl, '_blank')}
        style={{
          padding: '10px 20px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          fontSize: '16px',
          marginTop: '20px'
        }}
      >
        Take the Survey
      </button>
    </Modal>
  );
};

export default SurveyModal;