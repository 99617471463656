import { useState } from 'react';
import useProgUpload from '../react-query/useProgUpload';
import styles from '../stylez/ProgrammingUploadPage.module.css';

const ProgUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const { mutate: uploadFile, isLoading, isError, isSuccess, error } = useProgUpload();

    
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = () => {
        if (selectedFile) {
          uploadFile(selectedFile);
        }
    };


    return (
    <div className={styles.programmingUploadPageContainer}>
      <h1 className={styles.title}>Programming Upload</h1>
      <div className={styles.uploadCard}>
      <div className={styles.inputContainer}>
          {selectedFile && <p className={styles.fileName}>{selectedFile.name}</p>}
            <input 
                type="file" 
                id="fileUpload" 
                className={styles.fileInput} 
                onChange={handleFileChange} 
            />
            <label htmlFor="fileUpload" className={styles.fileLabel}>
                Choose File
            </label>
            <button 
                onClick={handleUpload} 
                className={styles.uploadButton}
                disabled={isLoading}
            >
                {isLoading ? 'Uploading...' : 'Upload'}
            </button>
        </div>
        {/* {isError && <p className={styles.errorText}>Error: {error.message}</p>}
        {isSuccess && <p className={styles.successText}>File uploaded successfully!</p>} */}
      </div>
    </div>
  );
};
export default ProgUpload;